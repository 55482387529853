import React, { useEffect, useState } from 'react'
import NftActivity from './NftActivity'
import ListedPrice from './SubComponents/ListedPrice'
import OwnerInfo from './SubComponents/OwnerInfo'
import { BackendUrl } from '../../config/constants'
import { getImageFromUri, getNameFromUri, getDescriptionFromUri } from '../../utils/functions'
import { useModal } from 'react-simple-modal-provider'
export default function NFT({ nft, tokenId }) {
  const [image, setImage] = useState(null)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [listedPrice, setListedPrice] = useState(null)
  const [secondPrice, setSecondPrice] = useState(null)
  const [highestBid, setHighestBid] = useState(null)
  const [allBids, setAllBids] = useState([])
  const [marketItemId, setMarketItemId] = useState(null)
  const [collection, setCollection] = useState(null)
  const [creator, setCreator] = useState(null)
  const [owner, setOwner] = useState(null)
  const [biddingEnabled, setBiddingEnabled] = useState(false)
  const [listingStatus, setListingStatus] = useState(false)
  const [sales, setSales] = useState([])
  // const []

  // const { account } = useEthers()
  const [token, setToken] = useState(null)
  const { open: openLoadingModal, close: closeLoadingModal } = useModal('LoadingModal')

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])

  useEffect(() => {
    async function getBids() {
      const res = await fetch(`${BackendUrl}/bids/${nft.nft_address}/${tokenId}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      setAllBids(data)
    }
    getBids()
  }, [nft, token])

  useEffect(() => {
    async function getSales() {
      const res = await fetch(`${BackendUrl}/allSales`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      setSales(data)
    }
    getSales()
  }, [nft, token])

  useEffect(() => {
    // set hightest bid
    if (allBids.length > 0) {
      const highest = allBids.sort((a, b) => b.bid_amount - a.bid_amount)[0]
      setHighestBid(highest.bid_amount)
    }
  }, [allBids])

  useEffect(() => {
    const getMeta = async () => {
      const image = await getImageFromUri(nft.uri)
      setImage(image)
      const name = await getNameFromUri(nft.uri)
      setName(name)
      const description = await getDescriptionFromUri(nft.uri)
      setDescription(description)
      document.title = name || 'NFT'
    }

    const fetchListingData = async () => {
      try {
        // Fetch listing data from backend using nft_address and token_id
        const response = await fetch(`${BackendUrl}/nftListing/${nft.nft_address}/${tokenId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const data = await response.json()
        if (response.ok) {
          setListedPrice(data.asking_price)
          setMarketItemId(data.item_id)
          setBiddingEnabled(data.bidding)
          setListingStatus(data.status)
        } else {
          console.error('Failed to fetch listing data:', data)
        }
      } catch (error) {
        console.error('Error fetching listing data:', error)
      }
    }

    getMeta()
    fetchListingData()
  }, [nft, token])
  console.log(nft, 'nft')
  useEffect(() => {
    const getCollection = async () => {
      const res = await fetch(`${BackendUrl}/nftCollection/${nft.nft_collection_address}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      setCollection(await res.json())
      document.title = collection.name
      return
    }
    getCollection()
  }, [nft, token])

  useEffect(() => {
    async function getUser() {
      openLoadingModal()
      const response = await fetch(`${BackendUrl}/user/${collection.owner}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      closeLoadingModal()
      if (data.detail === 'User not found') {
        return
      }
      setCreator(data)
    }
    async function getOwner() {
      openLoadingModal()
      const response = await fetch(`${BackendUrl}/user/${nft.recipient}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      closeLoadingModal()
      const data = await response.json()
      if (data.detail === 'User not found') {
        return
      }
      setOwner(data)
    }
    if (nft.recipient && token) {
      getOwner()
    }

    if (collection && token) {
      getUser()
    }
  }, [collection, nft, token])
  return (
    <div className="w-full flex flex-col md:flex-row gap-10 justify-center px-4 md:px-[10%] mt-12 mb-10 bg-white dark:bg-black-shade2-background h-full">
      <div className="nft-image w-full md:w-1/2">
        <div className="w-full flex justify-end">
          <img src="/images/nft/resize.svg" alt="resize" className="absolute z-10 mt-7 mr-7" />
          <img
            className="w-full"
            src={image}
            alt="nft"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = '/images/home/nfts/nft.svg'
            }}
          />
        </div>

        <div className="hidden md:block tabs">
          <NftActivity
            nft={nft}
            allBids={allBids}
            collection={collection}
            tokenId={tokenId}
            sales={sales}
            token={token}
          />
        </div>
      </div>

      <div className="w-full md:w-1/2">
        <div className="flex justify-between">
          <div className="font-gilroy text-2xl font-bold text-dark-text dark:text-dark-white-color">{name}</div>

          <div className="flex items-center">
            <div className="flex items-center bg-white dark:bg-black-shade2-background mr-2 rounded-md h-8 px-3">
              <img src="/images/nft/heart.svg" alt="heart" />
              <span className="font-gilroy text-sm font-semibold text-[#807373] dark:text-dark-gray ml-2">
                {nft.likes ? nft.likes : '0'}
              </span>
            </div>
            <div className="w-8 h-8 bg-white dark:bg-black-shade2-background rounded-md flex items-center justify-center">
              <div className="dot w-1 h-1 mr-1 bg-dark-text dark:bg-dark-white-color rounded-full" />
              <div className="dot w-1 h-1 mr-1 bg-dark-text dark:bg-dark-white-color rounded-full" />
              <div className="dot w-1 h-1 bg-dark-text dark:bg-dark-white-color rounded-full" />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <img src="/images/home/collections/cards/verified.svg" alt="heart" />
          <span className="font-gilroy ml-2 text-[#807373] dark:text-dark-gray font-semibold">{collection?.name}</span>
        </div>

        <div className="border-b border-t border-dashed flex py-5 justify-between border-[#767779] border-opacity-30 mt-7">
          {creator ? (
            <OwnerInfo box_for={'Creator'} name={creator?.name} image={BackendUrl + '/' + creator?.profile_picture} />
          ) : (
            <OwnerInfo box_for={'Creator'} name={'Unknown'} image={BackendUrl + '/' + creator?.profile_picture} />
          )}
          {owner ? (
            <OwnerInfo box_for={'Current Owner'} name={owner?.name} image={BackendUrl + '/' + owner?.profile_picture} />
          ) : (
            <OwnerInfo
              box_for={'Current Owner'}
              name={nft.recipient.slice(0, 4) + '...' + nft.recipient.slice(-4)}
              image={BackendUrl + '/' + owner?.profile_picture}
            />
          )}
        </div>

        <div className={`border-b mb-7 border-dashed flex flex-col py-5 border-[#767779] border-opacity-30`}>
          <span className="text-xl font-gilroy font-bold text-dark-text dark:text-dark-white-color">Description</span>

          <span className="font-gilroy text-light-text dark:text-dark-text-color font-medium mt-5">{description}</span>
        </div>

        <div className="">
          <ListedPrice
            image={BackendUrl + image}
            listed_price={listedPrice || nft.listed_price}
            second_price={secondPrice || nft.second_price}
            highest_bid={highestBid || nft.highest_bid}
            owner={nft.recipient}
            tokenId={tokenId}
            tokenAddress={nft.nft_address}
            marketItemId={marketItemId}
            copies={nft.copies}
            biddingEnabled={biddingEnabled}
            listingStatus={listingStatus}
            tokenBearer={token}
          />
        </div>
      </div>

      <div className="md:hidden tabs">
        <NftActivity allBids={allBids} nft={nft} tokenId={tokenId} sales={sales} token={token} />
      </div>
    </div>
  )
}
