import React, { useEffect, useState } from 'react'
import Styles from './Card.module.css'
import { getImageFromUri, getNameFromUri, getDescriptionFromUri } from '../../../utils/functions'
import { BackendUrl } from '../../../config/constants'
import { useEthers } from '@usedapp/core'
import { Link } from 'react-router-dom'
export default function Card({
  id,
  card_name,
  card_image,
  verified,
  card_category_icon,
  floor_price,
  owner,
  nft,
  small,
  index,
  item_quantity,
}) {
  const [image, setImage] = useState(null)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const { account } = useEthers()
  console.log(owner, 'item')
  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])
  useEffect(() => {
    const getMeta = async () => {
      const image = await getImageFromUri(card_image)
      setImage(image)
      console.log(image, 'image')
      const name = await getNameFromUri(card_image)
      setName(name)
      console.log(name, 'name')
      const description = await getDescriptionFromUri(card_image)
      setDescription(description)
      console.log(description, 'description')
    }
    getMeta()
  }, [card_image])

  useEffect(() => {
    async function getUser() {
      const response = await fetch(`${BackendUrl}/user/${owner}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      console.log(data)
      if (data.detail === 'User not found') {
        return
      }
      setUser(data)
      console.log(data)
    }
    if (owner && token) {
      getUser()
    }
  }, [owner, token])
  return (
    <a href={`/nfts/${id}/${index}`} className="cursor-pointer">
      <div className={`${Styles.card} bg-white dark:bg-black-shade2-background`}>
        <div className="card-image w-full flex justify-center ">
          {nft && (
            <img
              className="w-64"
              src={image}
              alt={name}
              onError={(e) => {
                e.target.onerror = null
                e.target.src = '/images/home/nfts/nft.svg'
              }}
            />
          )}
          {!nft && (
            <img
              className="w-64"
              src={card_image}
              alt={name}
              onError={(e) => {
                e.target.onerror = null
                e.target.src = '/images/home/nfts/nft.svg'
              }}
            />
          )}
        </div>

        <div className="card-info px-5 py-5 ">
          <div className="flex justify-between items-center">
            <div className="card-name flex items-center">
              <span className="font-gilroy font-bold text-dark-text dark:text-dark-white-color md:text-sm lg:text-xl">
                {nft ? name : card_name}
              </span>
              {verified && <img className="ml-2" src="/images/home/collections/cards/verified.svg" alt="verified" />}
            </div>
            {!small && (
              <div
                className={`floor-info font-gilroy font-medium ${
                  nft ? 'text-xs text-light-text dark:text-[#6C717A]' : 'text-sm text-[#807373] dark:text-dark-gray'
                } `}
              >
                {nft ? 'Listed Price' : 'Floor Price'}
              </div>
            )}
          </div>

          <div className="flex justify-between mt-2">
            <div className="card-items flex items-center">
              <img
                className="w-7 h-7 mr-1 xl:mr-3"
                src={BackendUrl + '/' + user?.profile_picture}
                alt={name}
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = '/images/create_nft/upload_file.jpeg'
                }}
              />
              <span
                className={`floor-info font-gilroy  ${
                  nft
                    ? 'text-xs text-light-text dark:text-[#6C717A] font-bold ml-1'
                    : 'text-sm text-[#807373] dark:text-dark-gray font-medium'
                } `}
              >
                {item_quantity}
              </span>
            </div>

            <div className="floor-price flex items-center md:mt-2 lg:mt-0">
              <img src={card_category_icon} alt={name} />
              <span className="ml-2 font-gilroy font-bold text-dark-text dark:text-dark-white-color">
                {floor_price ? floor_price : 'Not listed yet'}
              </span>
            </div>
          </div>
          <div className=" flex items-center md:mt-2 lg:mt-2 text-primary-green underline">
            {/* show owner like abc... */}
            <Link to={`/profile/${owner}`}>By: {owner.slice(0, 4) + '...' + owner.slice(-4)} </Link>
          </div>
        </div>
      </div>
    </a>
  )
}
